import { Vue } from 'vue-property-decorator';
export default Vue.extend({
    computed: {
        currentUser: function () {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        getCurrentUser: function () {
            return this.$store.state.auth.user;
        },
        isAdmin: function () {
            return this.currentUser.roles.includes('ROLE_ADMIN');
        },
        isOfficeUser: function () {
            return this.currentUser.roles.includes('ROLE_OFFICE');
        },
        isOfficeUserOrAdmin: function () {
            return this.currentUser.roles.includes('ROLE_OFFICE') || this.currentUser.roles.includes('ROLE_ADMIN');
        },
        isExternalUser: function () {
            return this.currentUser.roles.includes('ROLE_EXTERNAL');
        },
        isLagerPUser: function () {
            return this.currentUser.roles.includes('ROLE_LAGERP');
        },
    },
});
