var TokenService = /** @class */ (function () {
    function TokenService() {
    }
    TokenService.prototype.getLocalRefreshToken = function () {
        if (localStorage.user) {
            var user = JSON.parse(localStorage.user);
            return user.refreshToken;
        }
        return "";
    };
    TokenService.prototype.getLocalAccessToken = function () {
        if (localStorage.user) {
            var user = JSON.parse(localStorage.user);
            return user.accessToken;
        }
        return "";
    };
    TokenService.prototype.updateLocalAccessToken = function (token) {
        var user = JSON.parse(localStorage.user);
        user.accessToken = token;
        localStorage.setItem('user', JSON.stringify(user));
    };
    TokenService.prototype.updateLocalRefreshToken = function (refreshToken) {
        var user = JSON.parse(localStorage.user);
        user.refreshToken = refreshToken;
        localStorage.setItem('user', JSON.stringify(user));
    };
    TokenService.prototype.getRefreshTokenExpirationTs = function () {
        if (this.getUser()) {
            return this.getUser().refreshTokenxpirationTs;
        }
        return 0;
    };
    TokenService.prototype.getUser = function () {
        if (localStorage.user) {
            return JSON.parse(localStorage.user);
        }
        return undefined;
    };
    TokenService.prototype.setUser = function (user) {
        // eslint-disable-next-line no-console
        // console.log(JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(user));
    };
    TokenService.prototype.removeUser = function () {
        localStorage.removeItem('user');
    };
    return TokenService;
}());
export default new TokenService();
