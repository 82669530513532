import { Vue } from 'vue-property-decorator';
import GlobalEventBus from "../services/GlobalEventBus";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
export default Vue.extend({
    mixins: [],
    data: function () {
        return {
            heartBeatIntervalId: 0,
            tabId: "", //unique tabId for the heartbeat message
            connected: false,
            socket: null,
        };
    },
    methods: {
        sendEditModeUpdate: function (editModeActive) {
            this.$configuration.socketHeartBeatStatus.editModeActive = editModeActive;
            this.sendHeartBeatMessage("editModeUpdate");
        },
        /**
         * send a message to the socket server to indicate that the user started editing a property field (focus has been set on the field)
         * @param {*} entityType the type of entity that of which the property field has been focused, e.g. "Meeting", "Customer", "Event" etc.
         * @param {*} entityId the entity id (primary key) that is currently open in the editing view and where the property that has been focused on belongs to
         * @param {*} propertyName the name of the entity property that has gotten the focus
         */
        sendStartEditSocketMessage: function (entityType, entityId, propertyName) {
            this.$configuration.socketHeartBeatStatus.focussedFieldId = propertyName;
            this.$configuration.socketHeartBeatStatus.entityId = entityId;
            this.sendHeartBeatMessage("StartEdit");
            if (this.$configuration.stompClient &&
                this.$configuration.stompClient.connected) {
                var status = {
                    statusType: "StartEdit",
                    entityType: entityType,
                    entityId: entityId,
                    propertyName: propertyName,
                    username: this.currentUser.username,
                };
                try {
                    this.$configuration.stompClient.send("/app/editstatus", JSON.stringify(status), {});
                }
                catch (error) {
                    // eslint-disable-next-line
                    console.error(error);
                }
            }
        },
        /**
         * send a message to the socket server to indicate that the user finished editing a property field (blur event has been called on a field)
         * @param {*} entityType the type of entity that of which the property field has been de-focused (blur event), e.g. "Meeting", "Customer", "Event" etc.
         * @param {*} entityId the entity id (primary key) that is currently open in the editing view and where the property that has been edited belongs to
         * @param {*} propertyName the name of the entity property that has been edited
         */
        sendStopEditSocketMessage: function (entityType, entityId, propertyName) {
            this.$configuration.socketHeartBeatStatus.focussedFieldId = null;
            this.$configuration.socketHeartBeatStatus.entityId = null;
            this.sendHeartBeatMessage("StopEdit");
            if (this.$configuration.stompClient &&
                this.$configuration.stompClient.connected) {
                var status = {
                    statusType: "StopEdit",
                    entityType: entityType,
                    entityId: entityId,
                    propertyName: propertyName,
                    username: this.currentUser.username,
                };
                try {
                    this.$configuration.stompClient.send("/app/editstatus", JSON.stringify(status), {});
                }
                catch (error) {
                    // eslint-disable-next-line
                    console.error(error);
                }
            }
        },
        /**
         * handle edit status update messages via socket connection.
         * These events occur when a user puts focus on an input field (to start editing) or focus is blurred (user leaves the field)
         */
        handleEditStatusUpdate: function (message) {
            if (message.body.startsWith("{")) {
                var eventMessageBody = JSON.parse(message.body);
                //check if current user did not cause this update itself, otherwise we just ignore it
                if (eventMessageBody.username != this.currentUser.username) {
                    // eslint-disable-next-line no-console
                    console.debug("got edit status update message from user " +
                        eventMessageBody.username, eventMessageBody);
                    if (eventMessageBody.statusType == "StartEdit") {
                        GlobalEventBus.$emit("StartEdit", eventMessageBody);
                    }
                    else if (eventMessageBody.statusType == "StopEdit") {
                        GlobalEventBus.$emit("StopEdit", eventMessageBody);
                    }
                    else {
                        // eslint-disable-next-line no-console
                        console.log("Unknown edit status update message received, statsType is not supported: ", eventMessageBody);
                    }
                }
            }
        },
        /**
         * handle socket message that contains infos about all users currently logged on to the system
         */
        handleUsersStatusUpdate: function (message) {
            if (message.body.startsWith("{")) {
                var eventMessageBody = JSON.parse(message.body);
                // eslint-disable-next-line no-console
                //console.debug("got user status update message", eventMessageBody);
                GlobalEventBus.$emit("UserStatusUpdate", eventMessageBody);
            }
        },
        /**
         * handle events from the /topic/updates topic that are received via websocket connection.
         * These events can be:
         *   - User Logs in
         *   - User created, deleted or updated an entity
         */
        handleUpdatesTopicMessage: function (message) {
            if (message.body.startsWith("{")) {
                var eventMessageBody = JSON.parse(message.body);
                // eslint-disable-next-line
                // console.log("got message of type: " + eventMessageBody.messageType);
                if (eventMessageBody.messageType == "ContentUpdateEvent") {
                    //check if event has been generated by another user, otherwise ignore
                    if (!this.currentUser ||
                        this.currentUser.username != eventMessageBody.initiatingUserName) {
                        //handle updates depending on the view we are on
                        GlobalEventBus.$emit("ContentUpdateEvent", eventMessageBody);
                        this.$bvToast.toast(eventMessageBody.message, {
                            autoHideDelay: 3000,
                            noCloseButton: true,
                            variant: "success",
                            solid: false,
                        });
                    }
                }
                else if (eventMessageBody.messageType == "UserConnectionEvent") {
                    if (!this.currentUser ||
                        this.currentUser.username != eventMessageBody.userName) {
                        switch (eventMessageBody.eventType) {
                            case "TYPE_LOGIN":
                                this.$bvToast.toast(eventMessageBody.userName + " hat sich am System angemeldet", {
                                    autoHideDelay: 2500,
                                    noCloseButton: true,
                                    variant: "success",
                                    solid: false,
                                });
                                break;
                            case "TYPE_WSCONNECT":
                                this.$bvToast.toast(eventMessageBody.userName + " hat sich verbunden", {
                                    autoHideDelay: 1500,
                                    noCloseButton: true,
                                    variant: "success",
                                    solid: false,
                                });
                                break;
                            default:
                                // eslint-disable-next-line
                                console.log("Got unhandled user Event: ", eventMessageBody);
                                break;
                        }
                    }
                }
                else {
                    this.$bvToast.toast(JSON.stringify(eventMessageBody), {
                        autoHideDelay: 2000,
                        noCloseButton: true,
                        variant: "danger",
                        solid: false,
                    });
                }
            }
            else {
                // eslint-disable-next-line
                // console.log("received non object event");
                this.$bvToast.toast(message.body, {
                    autoHideDelay: 1000,
                    noCloseButton: true,
                    variant: "success",
                    solid: false,
                });
            }
        },
        /**
         * send the first hello message to the server to indicate we are here and started listening
         */
        sendConnectionHello: function () {
            if (this.$configuration.stompClient &&
                this.$configuration.stompClient.connected) {
                var msg = { name: this.currentUser.username };
                this.$configuration.stompClient.send("/app/hello", JSON.stringify(msg), {});
            }
        },
        /**
         * assemble and send an actual heartbeat message to the server (to the /app/heartbeat topic)
         */
        sendHeartBeatMessage: function (msg) {
            console.log("Heartbeat function called for " + msg);
            if (this.$configuration.stompClient &&
                this.$configuration.stompClient.connected) {
                //set current URL and then send heartbeat info
                this.$configuration.socketHeartBeatStatus.activePageUrl = this.$route.fullPath;
                var heartBeatMessage = {
                    userTabStatus: this.$configuration.socketHeartBeatStatus,
                };
                try {
                    this.$configuration.stompClient.send("/app/heartbeat", JSON.stringify(heartBeatMessage), {});
                }
                catch (error) {
                    // eslint-disable-next-line
                    console.error(error);
                }
            }
        },
        /**
         * Start an interval to send heartbeat messages to the server every 5 seconds
         */
        startHeartBeat: function () {
            //start sending heartbeat message every 5 seconds
            if (this.heartBeatIntervalId) {
                //clear previous interval first if any
                this.stopHeartBeat();
            }
            this.heartBeatIntervalId = setInterval(this.sendHeartBeatMessage, 5000);
        },
        /**
         * clear the heartbeat interval to stop sending heartbeat messages to the server
         */
        stopHeartBeat: function () {
            if (this.heartBeatIntervalId) {
                clearInterval(this.heartBeatIntervalId);
                this.heartBeatIntervalId = null;
            }
        },
        /**
         * connect to websocket server and subscribe to the different topics and start sending heartbeat messages
         */
        connect: function () {
            var _this = this;
            this.socket = new SockJS(this.$configuration.BASE_URL_WEBSOCKET + "gs-guide-websocket");
            this.$configuration.stompClient = Stomp.over(this.socket, { debug: false });
            //only generate this id once per app instance
            if (this.$configuration.socketHeartBeatStatus.tabId == null) {
                this.$configuration.socketHeartBeatStatus.tabId = this.generateTabId();
            }
            if (this.$configuration.socketHeartBeatStatus.username == null) {
                this.$configuration.socketHeartBeatStatus.username = this.currentUser.username;
            }
            this.$configuration.stompClient.connect({}, 
            // eslint-disable-next-line
            function (frame) {
                _this.connected = true;
                //handle entity updates (CRUD) from other users
                _this.$configuration.stompClient.subscribe("/topic/updates", function (message) {
                    _this.handleUpdatesTopicMessage(message);
                });
                //handle edit status information (blur and focus events on intput field from other users)
                _this.$configuration.stompClient.subscribe("/topic/editstatus", function (message) {
                    _this.handleEditStatusUpdate(message);
                });
                //handle all global user status updates (coming in in every 5 seconds)
                _this.$configuration.stompClient.subscribe("/topic/userstatus", function (message) {
                    _this.handleUsersStatusUpdate(message);
                });
                _this.startHeartBeat();
                _this.sendConnectionHello();
            }, function (error) {
                if (error.type && error.type === "close") {
                    _this.$bvToast.toast(error.reason, {
                        title: "Socket-Verbindug zu Server fehlgeschlagen",
                        autoHideDelay: 2000,
                        variant: "danger",
                        solid: true,
                    });
                    window.setTimeout(_this.connect, 5000);
                }
                else {
                    _this.$bvToast.toast(JSON.stringify(error), {
                        title: "Socket-Verbindug zu Server fehlgeschlagen",
                        autoHideDelay: 5000,
                        variant: "danger",
                        solid: true,
                    });
                    // eslint-disable-next-line
                    console.error("Stomp error", error);
                }
                _this.connected = false;
            });
        },
        /**
         * disconnect from websocket server and stop sending heartbeat messages
         */
        disconnect: function () {
            if (this.$configuration.stompClient) {
                this.stopHeartBeat();
                this.$configuration.stompClient.disconnect();
            }
            this.connected = false;
        },
        /*************************** HELPER FUNCTIONS  ******************************/
        /**
         * generate a "unique" ID for this instance of the APP which is used to identify a browser tab against the backend (if user opens more than one tab)
         */
        generateTabId: function () {
            var dt = new Date().getTime();
            var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
            });
            return uuid;
        },
    },
    computed: {
        currentUser: function () {
            return this.$store.state.auth.user;
        },
    },
});
