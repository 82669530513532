import { Vue } from 'vue-property-decorator';
import TokenService from '../services/token.service';
import moment from 'moment';
export default Vue.extend({
    data: function () {
        return {
            sessionTimeoutFormatted: '',
            sessionTimeoutRemainingSeconds: 0,
            sessionTimeoutRemainingFormatted: '',
        };
    },
    methods: {
        updateSessionLifetimeDetails: function () {
            var ts = TokenService.getRefreshTokenExpirationTs();
            if (ts) {
                this.sessionTimeoutFormatted = moment.unix(ts / 1000).format('HH:mm:ss (DD.MM.YYYY)');
                this.sessionTimeoutRemainingSeconds = (ts - Date.now()) / 1000;
                if (this.sessionTimeoutRemainingSeconds <= 0) {
                    this.sessionTimeoutRemainingSeconds = 0;
                    this.sessionTimeoutRemainingFormatted = '00:00:00';
                }
                else {
                    var date = new Date();
                    var secondsToSet = Number(this.sessionTimeoutRemainingSeconds.toFixed(0));
                    date.setSeconds(secondsToSet);
                    this.sessionTimeoutRemainingFormatted = date.toISOString().substr(11, 8);
                }
            }
            else {
                this.sessionTimeoutFormatted = '';
                this.sessionTimeoutRemainingFormatted = '';
                this.sessionTimeoutRemainingSeconds = 0;
            }
        },
    }
});
